.table-container {
  width: 99%;
  max-width: 1000px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: scroll;
  margin-top: 1.5rem;
}

table {
  overflow: scroll;
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 15px 20px;
  text-align: left;
}

th {
  background-color: #3b3f5c;
  color: #fff;
  text-transform: uppercase;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #eaeaea;
}

.status {
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

.status.new {
  background-color: #4caf50;
}

.status.used {
  background-color: #f44336;
}

.status.pending {
  background-color: #ff9800;
}

.generateTokenForm {
  width: 18rem;
  height: auto;
  padding: 13px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.generateTokenForm div {
  display: flex;
  gap: 5px;
  flex-direction: column;
  margin-bottom: 1rem;
}

.generateTokenForm input,
select {
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
}
